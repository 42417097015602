import Logo from '@assets/images/iwanejko-taczkowska-legal-and-advisory-logo.svg';
import Layout from '@components/layout/Layout';
import LanguageProvider from '@lib/language.provider';
import { i18n } from '@lingui/core';
import { t } from '@lingui/macro';
import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';

const WBudowiePage = ({ pageContext }) => {
	i18n.activate(pageContext.language);

	return (
		<LanguageProvider>
			<Layout>
				<main>
					<div className="flex flex-col lg:grid h-screen lg:grid-cols-2 auto-rows-fr">
						<div className="flex justify-center items-center h-72 lg:h-screen">
							<StaticImage
								className="block object-cover w-full lg:h-full h-full"
								placeholder={'none'}
								src={'../assets/images/home-photo-01.jpg'}
								alt={t`Iwanejko-Taczkowska - Legal & Advisory - Specjalizacje`}
							/>
						</div>
						<div className="flex flex-col justify-center items-center p-8 h-full lg:h-screen">
							<Logo className="mb-14 w-auto h-14 transition duration-500 fill-current md:h-16" />
							<h1 className="py-4 px-4 w-full max-w-sm text-center border-t border-gray-medium">
								Strona w budowie, odwiedź nas wkrótce.
							</h1>
						</div>
					</div>
				</main>
			</Layout>
		</LanguageProvider>
	);
};

export default WBudowiePage;
